import { t } from "./Localization.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Route, toPath, navigate } from "./Router.js";
import { singleton as singleton_1, cons, empty as empty_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useState_FCFD9EF } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { Cookies_save, Cookies_load } from "./LocalStorage.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Render } from "./SubscribePopup.js";
import { View_SwitchLanguage } from "./Header.js";
import { Cookies_CookiesAccepting, Cookies_EnabledCookies } from "./Shared/Shared.js";
import { Analytics_pageView } from "./Google.js";

export function p(key) {
    return t("cookies." + key);
}

export function CookiesButton() {
    const props = ofArray([["className", join(" ", ["is-dark", "is-uppercase", "is-fullwidth", "footer-button"])], ["children", p("cookies")], ["onClick", (_arg1) => {
        navigate(toPath(new Route(22)));
    }]]);
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function AcceptCookies() {
    const patternInput = useReact_useState_FCFD9EF(Cookies_load);
    const setCookingSetting = patternInput[1];
    const cookiesSetting = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((cookiesSetting.tag === 0) ? true : false);
    const setVisible = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const acceptPixie = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const acceptGoogle = patternInput_3[0];
    return createElement("div", {
        className: "footer-buttons-container",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((cookiesSetting.tag === 0) ? singleton(null) : singleton(createElement(Render, null)), delay(() => {
            let props_47;
            return singleton((props_47 = toList(delay(() => append(singleton(["style", {
                zIndex: 1000,
            }]), delay(() => append(patternInput_1[0] ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", join(" ", ["is-family-secondary"])]), delay(() => {
                let props_45, props_5, props_2, props_34, value_26, props_14, props_10, props_12, props_23, props_16, elms, props_31, props_25, elms_1, props_43;
                return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (props_45 = ofArray([["className", join(" ", ["modal_cookies_terms"])], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", join(" ", ["has-background-white", "is-family-secondary"])], ["style", {
                    borderBottomStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                }], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["is-uppercase", "has-background-white", "has-text-black"])], ["children", p("acceptCookiesTitle")]]), createElement("p", createObj(Helpers_combineClasses("modal-card-title", props_2)))), createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "flex-end",
                    },
                    children: Interop_reactApi.Children.toArray([createElement(View_SwitchLanguage, null)]),
                })])]]), createElement("header", createObj(Helpers_combineClasses("modal-card-head", props_5)))), (props_34 = ofArray([["className", join(" ", ["has-background-white", "has-text-grey-darker"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        paddingTop: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(value_26 = p("acceptCookiesText"), createElement("p", {
                        children: [value_26],
                    })), createElement("a", {
                        href: toPath(new Route(22)),
                        target: "_blank",
                        children: p("acceptCookiesLink"),
                    })]),
                }), createElement("div", {
                    style: {
                        paddingTop: 40,
                        paddingBottom: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-mobile"], ["className", "mb-3"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["style", {
                        overflow: "visible",
                        whiteSpace: "normal",
                        display: "contents",
                    }], ["children", p("acceptTextOne")]]), createElement("div", createObj(Helpers_combineClasses("level-left", props_10)))), (props_12 = ofArray([["className", "has-text-grey"], ["children", p("acceptTextOnePart2")]]), createElement("div", createObj(Helpers_combineClasses("level-right", props_12))))])]]), createElement("nav", createObj(Helpers_combineClasses("level", props_14)))), (props_23 = ofArray([["className", "is-mobile"], ["className", "mb-1"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["style", {
                        overflow: "visible",
                        whiteSpace: "normal",
                        display: "contents",
                    }], ["children", p("acceptTextTwo")]]), createElement("div", createObj(Helpers_combineClasses("level-left", props_16)))), (elms = singleton_1(createElement("div", {
                        children: Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("switch", ofArray([["id", "acceptPixie"], ["className", "is-rounded"], ["checked", acceptPixie], ["onChange", (ev) => {
                            patternInput_2[1](ev.target.checked);
                        }]]))))), createElement("label", {
                            htmlFor: "acceptPixie",
                            children: "",
                        })]),
                    })), createElement("div", {
                        className: "level-right",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))])]]), createElement("nav", createObj(Helpers_combineClasses("level", props_23)))), (props_31 = ofArray([["className", "is-mobile"], ["className", "mb-1"], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["style", {
                        overflow: "visible",
                        whiteSpace: "normal",
                        display: "contents",
                    }], ["children", p("acceptTextThree")]]), createElement("div", createObj(Helpers_combineClasses("level-left", props_25)))), (elms_1 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("switch", ofArray([["id", "acceptGoogle"], ["className", "is-rounded"], ["checked", acceptGoogle], ["onChange", (ev_1) => {
                        patternInput_3[1](ev_1.target.checked);
                    }]]))))), createElement("label", {
                        htmlFor: "acceptGoogle",
                        children: "",
                    })]), createElement("div", {
                        className: "level-right",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))])]]), createElement("nav", createObj(Helpers_combineClasses("level", props_31))))]),
                })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_34)))), (props_43 = ofArray([["className", join(" ", ["has-background-white"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_40, props_36, props_38;
                    return (cookiesSetting.tag === 0) ? singleton(createElement("div", {
                        style: {
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "is-black"], ["children", p("acceptSelected")], ["onClick", (_arg1_1) => {
                            Cookies_save(new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(acceptPixie, acceptGoogle)));
                            setCookingSetting(new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(acceptPixie, acceptGoogle)));
                            Analytics_pageView(toPath(new Route(0)));
                            setVisible(false);
                        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_36)))), (props_38 = ofArray([["className", "is-primary"], ["children", p("acceptAll")], ["onClick", (_arg2) => {
                            Cookies_save(new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(true, true)));
                            setCookingSetting(new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(true, true)));
                            Analytics_pageView(toPath(new Route(0)));
                            setVisible(false);
                        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_38))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_40))))]),
                    })) : singleton(null);
                }))))]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_43))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-card", props_45))))])]);
            })))))))), createElement("div", createObj(Helpers_combineClasses("modal", props_47)))));
        })))))),
    });
}

export function Cookies() {
    let props_12, props_10, props_2, props, props_8, value_25;
    return createElement("div", {
        className: "footer-buttons-container",
        children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["is-family-secondary"])], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", join(" ", ["modal_cookies_terms"])], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["has-background-white", "is-family-secondary"])], ["style", {
            borderBottomStyle: "solid",
            borderWidth: 1,
            borderColor: "#E5E5E5",
        }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", join(" ", ["is-uppercase", "has-background-white", "has-text-black"])], ["children", p("cookies")]]), createElement("p", createObj(Helpers_combineClasses("modal-card-title", props))))])]]), createElement("header", createObj(Helpers_combineClasses("modal-card-head", props_2)))), (props_8 = ofArray([["className", join(" ", ["has-background-white", "has-text-grey-darker"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: "modal_cookies_title",
            children: Interop_reactApi.Children.toArray([createElement("h1", {
                style: {
                    fontSize: 20 + "px",
                    fontWeight: 400,
                },
                children: p("title.cookies"),
            })]),
        }), createElement("div", {
            style: {
                paddingTop: 20,
            },
            children: Interop_reactApi.Children.toArray([(value_25 = p("text.cookies"), createElement("p", {
                children: [value_25],
            }))]),
        })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-card", props_10))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_12))))]),
    });
}

