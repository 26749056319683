import { t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { Subscribe_SubscribeAccepting, Subscribe_Subscriber, Subscribe_Subscriber$reflection } from "../Shared/Shared.js";
import { record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Result_MapError, FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { subscribeToEmailApi } from "../Communication.js";
import { EmailModule_ofString, EmailModule_toString, EmailModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { FSharpMap__get_IsEmpty, empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { SubscribeToEmails_validateIf } from "../Validation.js";
import { SubscribeToMailchimp_save } from "../LocalStorage.js";
import { utcNow } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { Form_Field_errorsAsString, Form_Field_hasErrors, MediaQuery_customBreakpoints } from "../Common.js";
import { singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export function p(key) {
    return t("profileSettings." + key);
}

export function policy(key) {
    return t("subscribeToEmails." + key);
}

export class State extends Record {
    constructor(Form, FormErrors, NeedValidateForm, SaveFormOperation) {
        super();
        this.Form = Form;
        this.FormErrors = FormErrors;
        this.NeedValidateForm = NeedValidateForm;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function State$reflection() {
    return record_type("Client.Components.SubscribeToEMail.State", [], State, () => [["Form", Subscribe_Subscriber$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChange", "FirstNameChange", "LastNameChange", "ConfirmPolicyChange", "SaveForm", "Cancel"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Components.SubscribeToEMail.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

export function Cmd_subscribe(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(subscribeToEmailApi().subscribe(form), (_arg2) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, Result_MapError((_arg1) => {
        if (_arg1.tag === 1) {
            return policy("error");
        }
        else {
            return _arg1.fields[0];
        }
    }, _arg2)))))), (_arg3) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function init() {
    return [new State(new Subscribe_Subscriber("", "", EmailModule_empty, false), empty(), false, new Deferred$1(0)), Cmd_none()];
}

export function validate(state) {
    const patternInput = SubscribeToEmails_validateIf(state.NeedValidateForm, state.Form);
    return new State(patternInput[0], patternInput[1], state.NeedValidateForm, state.SaveFormOperation);
}

export function withForm(state, newState) {
    return new State(newState, state.FormErrors, state.NeedValidateForm, state.SaveFormOperation);
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord;
    if (msg.tag === 1) {
        return [validate(withForm(state, (inputRecord_1 = state.Form, new Subscribe_Subscriber(msg.fields[0], inputRecord_1.LastName, inputRecord_1.Email, inputRecord_1.ConfirmPolicy)))), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [validate(withForm(state, (inputRecord_2 = state.Form, new Subscribe_Subscriber(inputRecord_2.FirstName, msg.fields[0], inputRecord_2.Email, inputRecord_2.ConfirmPolicy)))), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [validate(withForm(state, (inputRecord_3 = state.Form, new Subscribe_Subscriber(inputRecord_3.FirstName, inputRecord_3.LastName, inputRecord_3.Email, !state.Form.ConfirmPolicy)))), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                SubscribeToMailchimp_save(new Subscribe_SubscribeAccepting(1, utcNow()));
            }
            else {
                SubscribeToMailchimp_save(new Subscribe_SubscribeAccepting(2));
            }
            return [new State(state.Form, state.FormErrors, state.NeedValidateForm, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else {
            const errors = SubscribeToEmails_validateIf(true, state.Form)[1];
            if (FSharpMap__get_IsEmpty(errors)) {
                return [new State(state.Form, errors, true, new Deferred$1(1)), Cmd_fromAsync(Cmd_subscribe(state.Form))];
            }
            else {
                return [new State(state.Form, errors, true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 5) {
        return init();
    }
    else {
        return [validate(withForm(state, (inputRecord = state.Form, new Subscribe_Subscriber(inputRecord.FirstName, inputRecord.LastName, msg.fields[0], inputRecord.ConfirmPolicy)))), Cmd_none()];
    }
}

export function Render(renderInputProps) {
    const unitVar1 = renderInputProps.unitVar1;
    const close = renderInputProps.close;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const fieldStyleOnError = ofArray([["borderColor", "#FF0000"], ["boxShadow", "none"]]);
    const isSaving = (state_1.SaveFormOperation.tag === 1) ? true : false;
    const props_50 = ofArray([["style", {
        borderTop: (((2 + "px ") + "solid") + " ") + "#FFFFFF",
        paddingTop: 20,
        marginTop: 20,
        display: "inline-block",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-size-3"], ["className", "has-text-white"], ["className", "homeTitleAuction"], ["children", policy("title")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))), delay(() => {
            let props_18, props_8, elms, props_5, props_3, props_16, props_40, props_38;
            const matchValue_1 = state_1.SaveFormOperation;
            switch (matchValue_1.tag) {
                case 1: {
                    return append(singleton_1((props_18 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_5 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("FirstName")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                        let value_28;
                        return append(singleton_1((value_28 = state_1.Form.FirstName, ["ref", (e) => {
                            if ((!(e == null)) ? (!equals(e.value, value_28)) : false) {
                                e.value = value_28;
                            }
                        }])), delay(() => append(singleton_1(["onChange", (ev) => {
                            dispatch(new Msg(1, ev.target.value));
                        }]), delay(() => singleton_1(["placeholder", p("firstName.placeholder")])))));
                    })))))))))), (props_3 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("FirstName")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_5))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_16 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "px-0"]) : ((width.tag === 1) ? singleton_1(["className", "px-0"]) : append(singleton_1(["className", "pr-0"]), delay(() => singleton_1(["className", "py-0"])))), delay(() => {
                        let elms_1, props_13, props_11;
                        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2((props_13 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("LastName")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                            let value_53;
                            return append(singleton_1((value_53 = state_1.Form.LastName, ["ref", (e_1) => {
                                if ((!(e_1 == null)) ? (!equals(e_1.value, value_53)) : false) {
                                    e_1.value = value_53;
                                }
                            }])), delay(() => append(singleton_1(["onChange", (ev_1) => {
                                dispatch(new Msg(2, ev_1.target.value));
                            }]), delay(() => singleton_1(["placeholder", p("lastName.placeholder")])))));
                        })))))))))), (props_11 = ofArray([["style", {
                            color: "#FF0000",
                        }], ["children", Form_Field_errorsAsString("LastName")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_13))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))])]);
                    })))), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))), delay(() => {
                        let props_28, props_26;
                        return append(singleton_1((props_28 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_26 = toList(delay(() => append(singleton_1(["className", "px-0"]), delay(() => append((width.tag === 0) ? singleton_1(["className", "py-0"]) : ((width.tag === 1) ? singleton_1(["className", "py-0"]) : singleton_1(["className", "py-2"])), delay(() => {
                            let elms_2, props_23, props_21;
                            return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_23 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("Email")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "email"]), delay(() => {
                                let value_84;
                                return append(singleton_1((value_84 = EmailModule_toString(state_1.Form.Email), ["ref", (e_2) => {
                                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_84)) : false) {
                                        e_2.value = value_84;
                                    }
                                }])), delay(() => append(singleton_1(["onChange", (ev_2) => {
                                    dispatch(new Msg(0, EmailModule_ofString(ev_2.target.value)));
                                }]), delay(() => singleton_1(["placeholder", p("email.placeholder")])))));
                            })))))))))), (props_21 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", Form_Field_errorsAsString("Email")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_21))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            }))])]);
                        })))))), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28))))), delay(() => {
                            let props_32, props_30;
                            return singleton_1((props_32 = ofArray([["className", "is-right"], ["className", "mr-0"], ["children", Interop_reactApi.Children.toArray([(props_30 = toList(delay(() => append(isSaving ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["className", "mr-0"]), delay(() => append(singleton_1(["className", join(" ", ["is-primary"])]), delay(() => singleton_1(["children", policy("button")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_30))))])], ["onClick", (e_3) => {
                                e_3.preventDefault();
                                dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
                            }]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_32)))));
                        }));
                    }));
                }
                case 2: {
                    const result = matchValue_1.fields[0];
                    return append(singleton_1((props_40 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["className", "px-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_34;
                        return (result.tag === 1) ? singleton_1(createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "px-4"], ["className", "py-4"], ["className", "is-size-6"], ["className", "has-background-warning"], ["children", result.fields[0]]]))))) : singleton_1((props_34 = ofArray([["className", "px-4"], ["className", "py-4"], ["className", "is-size-5"], ["className", "has-background-success"], ["className", "has-text-white"], ["children", policy("ok")]]), createElement("h1", createObj(Helpers_combineClasses("title", props_34)))));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_38))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_40))))), delay(() => {
                        let props_48, props_46, props_44, props_42;
                        return (result.tag === 1) ? singleton_1((props_48 = ofArray([["className", "is-right"], ["className", "mr-0"], ["children", Interop_reactApi.Children.toArray([(props_46 = ofArray([["className", "mr-0"], ["className", join(" ", ["is-primary"])], ["children", policy("tryAgain")]]), createElement("button", createObj(Helpers_combineClasses("button", props_46))))])], ["onClick", (e_5) => {
                            e_5.preventDefault();
                            dispatch(new Msg(5));
                        }]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_48))))) : singleton_1((props_44 = ofArray([["className", "is-right"], ["className", "mr-0"], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "mr-0"], ["className", join(" ", ["is-success"])], ["children", t("booking.Ok")]]), createElement("button", createObj(Helpers_combineClasses("button", props_42))))])], ["onClick", (e_4) => {
                            e_4.preventDefault();
                            dispatch(new Msg(5));
                            close();
                        }]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_44)))));
                    }));
                }
                default: {
                    return append(singleton_1((props_18 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_5 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("FirstName")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                        let value_28;
                        return append(singleton_1((value_28 = state_1.Form.FirstName, ["ref", (e) => {
                            if ((!(e == null)) ? (!equals(e.value, value_28)) : false) {
                                e.value = value_28;
                            }
                        }])), delay(() => append(singleton_1(["onChange", (ev) => {
                            dispatch(new Msg(1, ev.target.value));
                        }]), delay(() => singleton_1(["placeholder", p("firstName.placeholder")])))));
                    })))))))))), (props_3 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("FirstName")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_5))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_16 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "px-0"]) : ((width.tag === 1) ? singleton_1(["className", "px-0"]) : append(singleton_1(["className", "pr-0"]), delay(() => singleton_1(["className", "py-0"])))), delay(() => {
                        let elms_1, props_13, props_11;
                        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2((props_13 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("LastName")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                            let value_53;
                            return append(singleton_1((value_53 = state_1.Form.LastName, ["ref", (e_1) => {
                                if ((!(e_1 == null)) ? (!equals(e_1.value, value_53)) : false) {
                                    e_1.value = value_53;
                                }
                            }])), delay(() => append(singleton_1(["onChange", (ev_1) => {
                                dispatch(new Msg(2, ev_1.target.value));
                            }]), delay(() => singleton_1(["placeholder", p("lastName.placeholder")])))));
                        })))))))))), (props_11 = ofArray([["style", {
                            color: "#FF0000",
                        }], ["children", Form_Field_errorsAsString("LastName")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_13))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))])]);
                    })))), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))), delay(() => {
                        let props_28, props_26;
                        return append(singleton_1((props_28 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_26 = toList(delay(() => append(singleton_1(["className", "px-0"]), delay(() => append((width.tag === 0) ? singleton_1(["className", "py-0"]) : ((width.tag === 1) ? singleton_1(["className", "py-0"]) : singleton_1(["className", "py-2"])), delay(() => {
                            let elms_2, props_23, props_21;
                            return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_23 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("Email")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["type", "email"]), delay(() => {
                                let value_84;
                                return append(singleton_1((value_84 = EmailModule_toString(state_1.Form.Email), ["ref", (e_2) => {
                                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_84)) : false) {
                                        e_2.value = value_84;
                                    }
                                }])), delay(() => append(singleton_1(["onChange", (ev_2) => {
                                    dispatch(new Msg(0, EmailModule_ofString(ev_2.target.value)));
                                }]), delay(() => singleton_1(["placeholder", p("email.placeholder")])))));
                            })))))))))), (props_21 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", Form_Field_errorsAsString("Email")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_21))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            }))])]);
                        })))))), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28))))), delay(() => {
                            let props_32, props_30;
                            return singleton_1((props_32 = ofArray([["className", "is-right"], ["className", "mr-0"], ["children", Interop_reactApi.Children.toArray([(props_30 = toList(delay(() => append(isSaving ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["className", "mr-0"]), delay(() => append(singleton_1(["className", join(" ", ["is-primary"])]), delay(() => singleton_1(["children", policy("button")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_30))))])], ["onClick", (e_3) => {
                                e_3.preventDefault();
                                dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
                            }]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_32)))));
                        }));
                    }));
                }
            }
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("block", props_50)));
}

