import { useFeliz_React__React_useState_Static_1505, useReact_useState_FCFD9EF } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { SubscribeToMailchimp_save, Cookies_load, SubscribeToMailchimp_load } from "./LocalStorage.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { MediaQuery_customBreakpoints } from "./Common.js";
import { compare } from "./fable_modules/fable-library.3.6.1/Date.js";
import { utcNow, addDays } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, empty as empty_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { Subscribe_SubscribeAccepting } from "./Shared/Shared.js";
import { Render as Render_1 } from "./Components/SubscribeToEmail.js";
import { Img_subscribeHelander } from "./Css.js";

export function Render() {
    const patternInput = useReact_useState_FCFD9EF(SubscribeToMailchimp_load);
    const subscribeSetting = patternInput[0];
    const setSubscribeSetting = patternInput[1];
    const cookiesSetting = Cookies_load();
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((cookiesSetting.tag === 0) ? false : ((subscribeSetting.tag === 0) ? true : ((subscribeSetting.tag === 1) ? (compare(addDays(subscribeSetting.fields[0], 7), utcNow()) <= 0) : false)));
    const close = () => {
        patternInput_1[1](false);
    };
    const props_17 = toList(delay(() => append(singleton(["style", {
        zIndex: 1000,
    }]), delay(() => append(patternInput_1[0] ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let props_15;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (props_15 = ofArray([["style", {
            borderRadius: 6,
        }], ["className", "has-background-grey"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let children, props_3, props_5, props_13, props_8, props_11;
            return (width.tag === 0) ? singleton((children = ofArray([(props_3 = ofArray([["className", "previewImageCenterWithoutRatio"], ["style", {
                paddingTop: 30,
                paddingRight: 30,
                display: "flex",
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([createElement("button", {
                className: "delete",
                onClick: (_arg1) => {
                    if (SubscribeToMailchimp_load().tag === 2) {
                        close();
                    }
                    else {
                        const date_1 = utcNow();
                        SubscribeToMailchimp_save(new Subscribe_SubscribeAccepting(1, date_1));
                        setSubscribeSetting(new Subscribe_SubscribeAccepting(1, date_1));
                        close();
                    }
                },
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_5 = ofArray([["style", {
                display: "flex",
                alignSelf: "center",
                padding: ((((((0 + "px ") + 20) + "px ") + 20) + "px ") + 20) + "px",
            }], ["children", Interop_reactApi.Children.toArray([createElement(Render_1, {
                close: close,
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5))))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))) : ((width.tag === 1) ? singleton((children = ofArray([(props_3 = ofArray([["className", "previewImageCenterWithoutRatio"], ["style", {
                paddingTop: 30,
                paddingRight: 30,
                display: "flex",
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([createElement("button", {
                className: "delete",
                onClick: (_arg1) => {
                    if (SubscribeToMailchimp_load().tag === 2) {
                        close();
                    }
                    else {
                        const date_1 = utcNow();
                        SubscribeToMailchimp_save(new Subscribe_SubscribeAccepting(1, date_1));
                        setSubscribeSetting(new Subscribe_SubscribeAccepting(1, date_1));
                        close();
                    }
                },
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_5 = ofArray([["style", {
                display: "flex",
                alignSelf: "center",
                padding: ((((((0 + "px ") + 20) + "px ") + 20) + "px ") + 20) + "px",
            }], ["children", Interop_reactApi.Children.toArray([createElement(Render_1, {
                close: close,
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5))))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))) : singleton((props_13 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-7"], ["style", {
                display: "flex",
                alignSelf: "center",
                padding: 20,
            }], ["children", Interop_reactApi.Children.toArray([createElement(Render_1, {
                close: close,
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_11 = ofArray([["className", "previewImageCenterWithoutRatio"], ["style", {
                backgroundImage: ("url(\u0027" + Img_subscribeHelander) + "\u0027)",
                minHeight: 600,
                padding: 30,
                display: "flex",
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([createElement("button", {
                className: "delete",
                onClick: (_arg2) => {
                    if (SubscribeToMailchimp_load().tag === 2) {
                        close();
                    }
                    else {
                        const date_2 = utcNow();
                        SubscribeToMailchimp_save(new Subscribe_SubscribeAccepting(1, date_2));
                        setSubscribeSetting(new Subscribe_SubscribeAccepting(1, date_2));
                        close();
                    }
                },
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_13))))));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_15))))])]);
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_17)));
}

