import { t } from "./Localization.js";
import { rgba } from "./fable_modules/Feliz.1.45.0/Colors.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Payment_PaymentStatus } from "./Shared/Shared.js";
import { toString } from "./fable_modules/fable-library.3.6.1/Types.js";
import { singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { paymentApi, ordersApi } from "./Communication.js";
import { PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C } from "./bidflow/src/infrastructure/Auction.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { mediumInline, fullScreenMedium } from "./Components/Loader.js";
import { sleep } from "./fable_modules/fable-library.3.6.1/Async.js";

export function d(key) {
    return t("shoppingCart." + key);
}

export function o(key) {
    return t("orders." + key);
}

export const styleBox = ["style", {
    backgroundColor: "#fff",
    textAlign: "left",
    color: "#444",
    marginBottom: 10,
    boxShadow: (((((((0 + "px ") + 0) + "px ") + 2) + "px ") + 1) + "px ") + rgba(232, 229, 232, 1),
}];

export function confirmation(status, orderId) {
    let props_13, props_11, props_9, children, value_36, copyOfStruct;
    return createElement("div", {
        style: {
            padding: ((((((100 + "px ") + 0) + "px ") + 100) + "px ") + 0) + "px",
        },
        children: Interop_reactApi.Children.toArray([(props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-8"], styleBox, ["children", Interop_reactApi.Children.toArray([createElement("p", {
            className: "py-5",
            style: {
                textAlign: "center",
            },
            className: join(" ", ["icon-text"]),
            children: Interop_reactApi.Children.toArray([createElement("span", createObj(toList(delay(() => append(singleton(["className", join(" ", ["is-size-5-mobile", "is-size-3"])]), delay(() => (equals(status, new Payment_PaymentStatus(3)) ? append(singleton(["className", "has-text-success"]), delay(() => singleton(["children", d("confirmation")]))) : append(singleton(["style", {
                color: "rgb(214, 99, 99)",
            }]), delay(() => singleton(["children", d("error.payment")])))))))))), (children = toList(delay(() => (equals(status, new Payment_PaymentStatus(3)) ? singleton(createElement("i", {
                style: {
                    paddingLeft: 10,
                },
                className: "has-text-success",
                className: join(" ", ["fa", "fa-check-square", "is-size-5-mobile", "is-size-3"]),
            })) : singleton(createElement("i", {
                style: {
                    paddingLeft: 10,
                    color: "rgb(214, 99, 99)",
                },
                className: join(" ", ["fa", "fa-exclamation-circle", "is-size-5-mobile", "is-size-3"]),
            }))))), createElement("span", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))]),
        }), createElement("div", {
            className: "confirmation-window-text",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "py-2",
                children: Interop_reactApi.Children.toArray([(value_36 = d("thank.you"), createElement("span", {
                    children: [value_36],
                })), createElement("strong", {
                    className: "is-size-4",
                    className: "is-size-6-mobile",
                    children: (copyOfStruct = orderId, toString(copyOfStruct)),
                })]),
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_13))))]),
    });
}

export function LoadOrderAfterPayment(loadOrderAfterPaymentInputProps) {
    const orderId = loadOrderAfterPaymentInputProps.orderId;
    const paymentStatus = loadOrderAfterPaymentInputProps.paymentStatus;
    const order_1 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_2.Delay(() => singleton_2.Bind(ordersApi().getOrder(orderId), (_arg1) => {
        let matchValue, x_1, y, activePatternResult26246;
        const order = _arg1;
        return singleton_2.Return(new FSharpResult$2(0, {
            DefaultDeliveryAddress: (matchValue = order.Delivery, (matchValue.tag === 1) ? ((matchValue.fields[0] == null) ? "-" : ((x_1 = matchValue.fields[0][0], (y = matchValue.fields[0][1], `${y.Address}, ${y.ZipCode} ${y.City}`)))) : ((matchValue.fields[0].tag === 1) ? ((activePatternResult26246 = PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C(matchValue.fields[0].fields[0]), (activePatternResult26246.tag === 1) ? "Frihamnsgatan 58, 115 56 Stockholm, Magasin 6, entré 3" : ((activePatternResult26246.tag === 2) ? "Bronsyxegatan 9B 213 75 Malmö" : ((activePatternResult26246.tag === 3) ? "c/o Schulmanin Taideliike, Thomas Schulman, Kasarmikatu 2, 00140 Helsinki, Finland" : ((activePatternResult26246.tag === 4) ? "Unknown" : "Processvägen 3 435 33 Mölnlycke"))))) : "Österleden 2, 721 35 Västerås")),
            Order: order,
        }));
    })), [orderId]);
    let pattern_matching_result, error;
    if (order_1.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (order_1.tag === 3) {
        pattern_matching_result = 1;
        error = order_1.fields[0];
    }
    else if (order_1.tag === 2) {
        if (order_1.fields[0].tag === 0) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            const elms = singleton_1(fullScreenMedium(t("common." + "loading.lot")));
            return createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        case 1: {
            const props_1 = ofArray([["className", "is-danger"], ["children", error.message]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props_1)));
        }
        case 2: {
            return createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", "Error with getting order "]]))));
        }
        case 3: {
            return confirmation(paymentStatus, orderId);
        }
    }
}

export function LoadPayment(loadPaymentInputProps) {
    let loop, props_1;
    const orderId = loadPaymentInputProps.orderId;
    const payment_1 = useFeliz_React__React_useDeferred_Static_2344FC52((loop = ((count) => singleton_2.Delay(() => singleton_2.Bind(sleep(3000), () => singleton_2.Bind(paymentApi().getSveaPayment(orderId), (_arg2) => {
        const response = _arg2;
        if (response == null) {
            if (count === 0) {
                return singleton_2.Return(new FSharpResult$2(1, "Timeout"));
            }
            else if (response == null) {
                return singleton_2.ReturnFrom(loop(count - 1));
            }
            else {
                throw (new Error("Match failure"));
            }
        }
        else {
            const payment = response;
            return (equals(payment.Status, new Payment_PaymentStatus(3)) ? true : equals(payment.Status, new Payment_PaymentStatus(4))) ? singleton_2.Return(new FSharpResult$2(0, payment)) : singleton_2.ReturnFrom(loop(count - 1));
        }
    })))), loop(100)), [orderId]);
    let pattern_matching_result, error, msg, result;
    if (payment_1.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (payment_1.tag === 3) {
        pattern_matching_result = 1;
        error = payment_1.fields[0];
    }
    else if (payment_1.tag === 2) {
        if (payment_1.fields[0].tag === 0) {
            pattern_matching_result = 3;
            result = payment_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            msg = payment_1.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            const elms = singleton_1(mediumInline(d("loading")));
            return createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        case 1: {
            const elms_1 = singleton_1((props_1 = ofArray([["className", "is-danger"], ["children", error.message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_1)))));
            return createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            });
        }
        case 2: {
            const elms_2 = singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", msg]])))));
            return createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            });
        }
        case 3: {
            return createElement(LoadOrderAfterPayment, {
                paymentStatus: result.Status,
                orderId: result.OrderId,
            });
        }
    }
}

