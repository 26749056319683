import { Localized_translate, t } from "../Localization.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { indexed, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { isNullOrEmpty, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { collect, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { Img_faqHelanderNew } from "../Css.js";
import { large } from "../Components/Loader.js";

export function p(key) {
    return t("faq." + key);
}

export function FaqItem(faqItemInputProps) {
    let props_6, props_4, elms;
    const elementId = faqItemInputProps.elementId;
    const subtitle = faqItemInputProps.subtitle;
    const title = faqItemInputProps.title;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isShow = patternInput[0];
    return createElement("div", {
        style: {
            borderTop: (((2 + "px ") + "solid") + " ") + "#000000",
        },
        children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "py-4"], ["className", "px-0"], ["className", "mb-0"], ["className", "faqTitle"], ["onClick", (_arg1) => {
            patternInput[1](!isShow);
        }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "pb-0"], ["className", "px-0"], ["className", "is-8"], ["children", title]])))), (props_4 = ofArray([["className", "pb-0"], ["className", "px-0"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", toList(delay(() => append(singleton("fa"), delay(() => (isShow ? singleton("fa-angle-up") : singleton("fa-angle-down"))))))),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6)))), createElement("div", {
            className: join(" ", ["collapse-ex"]),
            style: createObj(toList(delay(() => (isShow ? append(singleton(["overflow", "visible"]), delay(() => append(singleton(["height", ~(~document.getElementById(elementId).clientHeight)]), delay(() => singleton(["overflow", "hidden"]))))) : empty())))),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                id: elementId,
                className: join(" ", ["growUp"]),
                children: Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("block", ofArray([["className", "pb-6"], ["children", subtitle]]))))]),
            })]),
        })]),
    });
}

export function Render() {
    let props, props_10;
    const info = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getFaqInfo(), []);
    return createElement("div", {
        style: {
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "pre-line",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "previewImageCenterWithoutRatio",
            style: {
                display: "flex",
                justifyContent: "flex-start",
                backgroundImage: ("url(\u0027" + Img_faqHelanderNew) + "\u0027)",
                minHeight: 500,
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["buyBox"]),
                children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-2"], ["className", "homeTitleAuction"], ["children", p("box1.title")]]), createElement("h1", createObj(Helpers_combineClasses("title", props)))), createElement("h2", {
                    className: join(" ", ["buy-text-h2"]),
                    children: p("box1.subtitle"),
                })]),
            })]),
        }), (props_10 = ofArray([["className", "py-6"], ["className", "px-6"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_5;
            switch (info.tag) {
                case 1: {
                    return singleton(large);
                }
                case 3: {
                    const er = info.fields[0];
                    return isNullOrEmpty(er.message) ? singleton(null) : singleton((props_5 = ofArray([["className", "is-danger"], ["children", er.message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_5)))));
                }
                case 2: {
                    return collect((matchValue) => {
                        let props_7;
                        const c = matchValue[1];
                        return append(singleton((props_7 = ofArray([["className", "is-size-2"], ["className", join(" ", ["homeTitleAuction"])], ["children", Localized_translate(c.SectionName)]]), createElement("h1", createObj(Helpers_combineClasses("title", props_7))))), delay(() => {
                            let elms;
                            return singleton((elms = toList(delay(() => collect((matchValue_1) => {
                                const q = matchValue_1[1];
                                return singleton(createElement(FaqItem, {
                                    title: Localized_translate(q.Question),
                                    subtitle: Localized_translate(q.Answer),
                                    elementId: `faq_${matchValue[0]}_${matchValue_1[0]}`,
                                }));
                            }, indexed(c.Questions)))), createElement("div", {
                                className: "block",
                                children: Interop_reactApi.Children.toArray(Array.from(elms)),
                            })));
                        }));
                    }, indexed(info.fields[0]));
                }
                default: {
                    return singleton(large);
                }
            }
        }))))]]), createElement("div", createObj(Helpers_combineClasses("container", props_10))))]),
    });
}

