import { t } from "../Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { Settings_CompanySettingsInfo_get_empty } from "../Shared/Shared.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Route, CustomerSpecific, toPath, navigate } from "../Router.js";
import { createElement } from "react";
import { Img_home5Helander, Img_home2Helander, Img_home1Helander } from "../Css.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export function p(key) {
    return t("home." + key);
}

export function ViewHomeBlock() {
    let elms, props_12, props_4, props_1, props_10, props_7, props_28, props_15, props_22, props_18, elms_1, props_24, props_37;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    const set$_1 = (settings.tag === 2) ? settings.fields[0] : Settings_CompanySettingsInfo_get_empty();
    const children_2 = ofArray([(elms = singleton((props_12 = singleton(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "homePromoLink"], ["onClick", (_arg1) => {
        navigate(toPath(new Route(24, new CustomerSpecific(1))));
    }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        className: "previewImageCenterWithoutRatio",
        src: Img_home1Helander,
    }), (props_1 = ofArray([["className", "mt-4"], ["className", "mb-1"], ["className", "homeTitleAuction"], ["children", t("helander.home.title1")]]), createElement("h1", createObj(Helpers_combineClasses("title", props_1)))), createElement("p", {
        children: t("helander.home.subtitle1"),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_10 = ofArray([["className", "homePromoLink"], ["onClick", (_arg2) => {
        navigate(toPath(new Route(24, new CustomerSpecific(0))));
    }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        className: "previewImageCenterWithoutRatio",
        src: Img_home2Helander,
    }), (props_7 = ofArray([["className", "mt-4"], ["className", "mb-1"], ["className", "homeTitleAuction"], ["children", t("helander.home.title2")]]), createElement("h1", createObj(Helpers_combineClasses("title", props_7)))), createElement("p", {
        children: t("helander.home.subtitle2"),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_28 = ofArray([["className", "mb-6"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-size-2"], ["className", "pt-6"], ["className", "mt-6"], ["className", "mb-4"], ["className", "pl-3"], ["className", "homeTitleAuction"], ["children", t("helander.home.title3")]]), createElement("h1", createObj(Helpers_combineClasses("title", props_15)))), (props_22 = singleton(["children", Interop_reactApi.Children.toArray([(props_18 = singleton(["children", Interop_reactApi.Children.toArray([createElement("p", {
        style: {
            whiteSpace: "pre-line",
        },
        children: t("helander.home.subtitle3"),
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (elms_1 = singleton(createElement("img", {
        className: "previewImageCenterWithoutRatio",
        src: Img_home5Helander,
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_22)))), createElement("div", {
        className: join(" ", ["buyContainer", "mx-3"]),
        style: {
            padding: 20,
            marginTop: 40,
            whiteSpace: "pre-line",
        },
        children: Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "mt-4"], ["className", "mb-3"], ["className", "homeTitleAuction"], ["children", t("helander.home.title4")]]), createElement("h1", createObj(Helpers_combineClasses("title", props_24)))), createElement("p", {
            children: t("helander.home.subtitle4"),
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_28)))), (props_37 = ofArray([["className", join(" ", ["paddingLeftRight-mobile45", "paddingBottom-desktop80-mobile10"])], ["style", {
        backgroundColor: "#efefef",
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([createElement("h2", {
        className: join(" ", ["paddingTop-desktop80-mobile10", "home-info-white-space-title", "homeTitleAuction"]),
        children: t("helander.home.follow"),
    }), createElement("div", {
        className: join(" ", ["is-centered"]),
        style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
        },
        children: Interop_reactApi.Children.toArray([createElement("a", {
            target: "_blank",
            className: join(" ", ["socialHome", "icon", "fab", "fa-facebook-f", "fa-2x", "home-social-media-icon"]),
            href: set$_1.FacebookLink,
        }), createElement("a", {
            target: "_blank",
            className: join(" ", ["socialHome", "icon", "fab", "fa-instagram", "fa-2x", "home-social-media-icon"]),
            href: set$_1.InstagramLink,
        }), createElement("a", {
            target: "_blank",
            className: join(" ", ["socialHome", "icon", "fab", "fa-x-twitter", "fa-2x", "home-social-media-icon"]),
            href: "https://twitter.com/auctionhelander",
        }), createElement("a", {
            target: "_blank",
            className: join(" ", ["socialHome", "icon", "fab", "fa-tiktok", "fa-2x", "home-social-media-icon"]),
            href: "https://www.tiktok.com/@huutokauppahelander",
        }), createElement("a", {
            target: "_blank",
            className: join(" ", ["socialHome", "icon", "fab", "fa-linkedin-in", "fa-2x", "home-social-media-icon"]),
            href: "https://fi.linkedin.com/company/auction-house-helander ",
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_37))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

