import { t } from "../Localization.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { large } from "../Components/Loader.js";
import { Img_dog2Helander, Img_dogHelander, Img_reaHelander, Img_aavaHelander, Img_borjeHelander, Img_armandsHelander, Img_petriHelander, Img_mikaHelander, Img_hanneHelander, Img_akiHelander, Img_merjaHelander, Img_grayJean, Img_contactsHelanderNew } from "../Css.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ScheduleHelander } from "./HelanderFooter.js";

export function p(s) {
    return t("helander.contacts." + s);
}

export function contactItem(name, position, image, email, phone, subtitle) {
    const props_9 = ofArray([["className", "is-half-mobile"], ["className", "is-half-tablet"], ["className", "is-one-quarter-desktop"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "contact-green-border",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    backgroundImage: ("url(\u0027" + image) + "\u0027)",
                },
                className: "contact-img-worker",
            })]),
        }), createElement("div", {
            className: "contact-info-background",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("h2", {
                children: name,
                className: "contact-name",
            })), delay(() => append(singleton(createElement("p", {
                children: position,
                className: "contact-info",
            })), delay(() => append((phone !== "") ? singleton(createElement("p", {
                children: phone,
                className: "contact-info",
            })) : empty(), delay(() => append((email !== "") ? singleton(createElement("a", {
                style: {
                    color: "#000000",
                },
                href: "mailto:" + email,
                children: email,
                className: "contact-info",
            })) : empty(), delay(() => ((subtitle !== "") ? singleton(createElement("p", {
                children: subtitle,
                className: "contact-info",
            })) : empty()))))))))))))),
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_9)));
}

export function View() {
    let props_3, props, props_28, props_26, props_19, value_42, value_55, value_62, props_24, props_49, props_31, props_35, props_39, props_43, props_47;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    switch (settings.tag) {
        case 1: {
            return large;
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            const set$ = settings.fields[0];
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        padding: ((60 + "px ") + 0) + "px",
                        backgroundImage: ("url(\u0027" + Img_contactsHelanderNew) + "\u0027)",
                        backgroundSize: "cover",
                        minHeight: 500,
                        display: "flex",
                        alignItems: "center",
                    },
                    className: "home-box-height",
                    children: Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "pl-3"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-2"], ["className", "my-0"], ["className", join(" ", ["homeTitleAuction", "is-size-3-mobile"])], ["children", p("title")]]), createElement("h1", createObj(Helpers_combineClasses("title", props)))), createElement("p", {
                        className: "py-0",
                        className: join(" ", ["is-size-4-mobile", "is-size-3"]),
                        children: p("subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_3))))]),
                }), (props_28 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["style", {
                    margin: 0,
                    padding: ((((30 + "px ") + 0) + "px ") + 30) + "px",
                    justifyContent: "space-between",
                }], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-5-desktop"], ["style", {
                    whiteSpace: "pre-line",
                }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["buy-title", "homeTitleAuction"]),
                    children: p("details.1"),
                }), createElement("p", {
                    className: "buy-text",
                    children: Interop_reactApi.Children.toArray([(value_42 = p("details.text1"), createElement("span", {
                        children: [value_42],
                    })), createElement("a", {
                        className: "link",
                        href: "mailto:info@huutokauppahelander.com",
                        children: "info@huutokauppahelander.com",
                    })]),
                }), createElement("br", {}), createElement("p", {
                    className: join(" ", ["buy-title", "homeTitleAuction"]),
                    children: p("details.2"),
                }), createElement("p", {
                    className: "buy-text",
                    children: Interop_reactApi.Children.toArray([(value_55 = p("details.text2"), createElement("span", {
                        children: [value_55],
                    }))]),
                }), createElement("br", {}), createElement("p", {
                    className: join(" ", ["buy-title", "homeTitleAuction"]),
                    children: p("details.3"),
                }), createElement("p", {
                    className: "buy-text",
                    children: Interop_reactApi.Children.toArray([(value_62 = p("details.text3"), createElement("span", {
                        children: [value_62],
                    })), createElement("a", {
                        className: "link",
                        href: "mailto:markkinointi@huutokauppahelander.com",
                        children: "markkinointi@huutokauppahelander.com",
                    })]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_19)))), (props_24 = ofArray([["className", join(" ", ["contact-grey-box"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["contactInfoText"]),
                    children: Interop_reactApi.Children.toArray([set$.Phone, createElement("br", {}), set$.Email, createElement("br", {}), set$.Address]),
                }), createElement(ScheduleHelander, null)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_26))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_28)))), createElement("div", {
                    style: {
                        backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
                        padding: ((((30 + "px ") + 0) + "px ") + 100) + "px",
                    },
                    children: Interop_reactApi.Children.toArray([(props_49 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("p", {
                        children: p("details.1"),
                        className: join(" ", ["buy-title", "homeTitleAuction", "pl-3"]),
                    }), (props_31 = ofArray([["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Merja Kurjenlampi", t("helander.contacts.customerServiceAndFinance"), Img_merjaHelander, "", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_31)))), createElement("br", {}), createElement("p", {
                        children: p("curators"),
                        className: join(" ", ["buy-title", "homeTitleAuction", "pl-3"]),
                    }), (props_35 = ofArray([["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Aki Syrjäläinen", t("helander.contacts.photographer"), Img_akiHelander, "", "", ""), contactItem("Hanne Merikanto", t("helander.contacts.headCurator"), Img_hanneHelander, "", "", ""), contactItem("Mika Sirén", t("helander.contacts.ceo"), Img_mikaHelander, "", "", ""), contactItem("Petri Parikka", t("helander.contacts.curatorBroker"), Img_petriHelander, "", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_35)))), createElement("br", {}), createElement("p", {
                        children: p("logisticsDelivery"),
                        className: join(" ", ["buy-title", "homeTitleAuction", "pl-3"]),
                    }), (props_39 = ofArray([["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Armands Krūmiņš", t("helander.contacts.logisticsPersonel"), Img_armandsHelander, "", "", ""), contactItem("Börje Blomqvist", t("helander.contacts.logisticManager"), Img_borjeHelander, "", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_39)))), createElement("br", {}), createElement("p", {
                        children: p("marketingCommunication"),
                        className: join(" ", ["buy-title", "homeTitleAuction", "pl-3"]),
                    }), (props_43 = ofArray([["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Aava Anttinen", t("helander.contacts.marketing"), Img_aavaHelander, "", "", ""), contactItem("Rea Räikkälä", t("helander.contacts.marketingCheif"), Img_reaHelander, "", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_43)))), createElement("br", {}), createElement("p", {
                        children: t("helander.contacts.officeDog"),
                        className: join(" ", ["buy-title", "homeTitleAuction", "pl-3"]),
                    }), (props_47 = ofArray([["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Kerttu", t("helander.contacts.officeDog"), Img_dogHelander, "", "", ""), contactItem("Migi", t("helander.contacts.officeDog"), Img_dog2Helander, "", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_47))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_49))))]),
                })]),
            });
        }
        default: {
            return large;
        }
    }
}

