import { t } from "../Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export function p(key) {
    return t("helander.termsAndConditions." + key);
}

export function Body() {
    let value_6, value_8, value_26, value_35, value_37, value_43, value_45, value_51, value_53, value_64, value_73, value_75, value_81, value_90, value_99, value_101, value_107, value_118, value_120, value_126, value_128, value_134, value_136, value_142, value_144, value_150, value_152;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                paddingTop: 20,
                whiteSpace: "pre-line",
            },
            className: join(" ", ["has-background-white", "has-text-grey-darker"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_6 = p("subtitle.1"), createElement("h1", {
                        children: [value_6],
                    }))]),
                }), (value_8 = p("text.1"), createElement("p", {
                    children: [value_8],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([createElement("h1", {
                        style: {
                            whiteSpace: "pre-line",
                        },
                        children: p("subtitle.2"),
                    })]),
                }), createElement("p", {
                    children: p("text.2"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_26 = p("subtitle.3"), createElement("h1", {
                        children: [value_26],
                    }))]),
                }), createElement("p", {
                    children: p("text.3"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_35 = p("subtitle.4"), createElement("h1", {
                        children: [value_35],
                    }))]),
                }), (value_37 = p("text.4"), createElement("p", {
                    children: [value_37],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_43 = p("subtitle.5"), createElement("h1", {
                        children: [value_43],
                    }))]),
                }), (value_45 = p("text.5"), createElement("p", {
                    children: [value_45],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_51 = p("subtitle.6"), createElement("h1", {
                        children: [value_51],
                    }))]),
                }), (value_53 = p("text.6"), createElement("span", {
                    children: [value_53],
                })), createElement("a", {
                    target: "_blank",
                    href: "https://www.kuvasto.fi",
                    children: "www.kuvasto.fi",
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_64 = p("subtitle.7"), createElement("h1", {
                        children: [value_64],
                    }))]),
                }), createElement("p", {
                    children: p("text.7"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_73 = p("subtitle.8"), createElement("h1", {
                        children: [value_73],
                    }))]),
                }), (value_75 = p("text.8"), createElement("p", {
                    children: [value_75],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_81 = p("subtitle.9"), createElement("h1", {
                        children: [value_81],
                    }))]),
                }), createElement("p", {
                    children: p("text.9"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_90 = p("subtitle.10"), createElement("h1", {
                        children: [value_90],
                    }))]),
                }), createElement("p", {
                    children: p("text.10"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_99 = p("subtitle.11"), createElement("h1", {
                        children: [value_99],
                    }))]),
                }), (value_101 = p("text.11"), createElement("p", {
                    children: [value_101],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_107 = p("subtitle.12"), createElement("h1", {
                        children: [value_107],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.12"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_118 = p("subtitle.13"), createElement("h1", {
                        children: [value_118],
                    }))]),
                }), (value_120 = p("text.13"), createElement("p", {
                    children: [value_120],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_126 = p("subtitle.14"), createElement("h1", {
                        children: [value_126],
                    }))]),
                }), (value_128 = p("text.14"), createElement("p", {
                    children: [value_128],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_134 = p("subtitle.15"), createElement("h1", {
                        children: [value_134],
                    }))]),
                }), (value_136 = p("text.15"), createElement("p", {
                    children: [value_136],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_142 = p("subtitle.16"), createElement("h1", {
                        children: [value_142],
                    }))]),
                }), (value_144 = p("text.16"), createElement("p", {
                    children: [value_144],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_150 = p("subtitle.17"), createElement("h1", {
                        children: [value_150],
                    }))]),
                }), (value_152 = p("text.17"), createElement("span", {
                    children: [value_152],
                }))]),
            })]),
        })]),
    });
}

export function Render() {
    return createElement("div", {
        style: {
            padding: ((((((60 + "px ") + 60) + "px ") + 120) + "px ") + 60) + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement(Body, null)]),
    });
}

